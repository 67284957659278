<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav :selectedLanguage="selectedLanguage" page="blogs" />

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <div class="blog-list-row">
              <router-link v-for="(data, i) in doc.bloglist[selectedLanguage].slice().reverse()" :key="i" class="blog-list-item"
                :to="{name: data.name}">
                <div class="blog-list-img">
                  <img :src="data.poster" alt="">
                </div>
                <div class="blog-list-content">
                  <div class="blog-list-title">
                    {{ data.title }}
                  </div>
                  <div class="blog-list-descript">
                    {{ data.desc }}
                  </div>
                  <div class="blog-list-date">
                    <!-- {{ $global.formatDate(new Date().getTime(), 'dd/MM/yyyy') }} -->
                    {{ data.date }}

                  </div>
                </div>
              </router-link>
            </div>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy/">Privacy Policy</a>
            |
            <a href="/contact-us/">Contact Us</a>
            |
            <a href="/about-us/">About Us</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><router-link :to="{name:`${selectedLanguage}-gb`}">GBWhatsApp</router-link></strong>
          </div>
        </div>
      </footer>
    </div>
    

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/blogspc.scss';
import doc from '@/documents/blogs.js';
import HeadNav from '@/components/HeadNav.vue';


export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data () {
    return {
      pageName: 'home',
      selectedLanguage: 'hi',
      doc: doc,
    };
  },
  mounted () { },
  methods: {
    goto (name) {
      // window.location.href = url;
      this.$router.push({name: `${name}`});
    },
    gotodownload () {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = `${this.selectedLanguage}/downloadpage` + params;
    },
    jump (url) {
      window.location.href = url;
    },
    gotoHome(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>
